import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { decryptData } from "../../services/encryptDecrypt";
import { agoraSessionEnd } from "./AgoraCommon";
import useTranslations from "../../custom-hooks/useTranslations";

const LaunchClassRoom = () => {
  const translations = useTranslations();
	const translation = translations[33]?.ApplicationConfiguration;
  const agoraCallDuration = translation?.AgoraCallDuration;
  const { encryptClassId, encryptSessionId } = useParams();
  const classId = decryptData(encryptClassId)
  const sessionId = decryptData(encryptSessionId);
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  const profileInfo = JSON.parse(sessionStorage.getItem("ProfileInfo"));
  const rtmToken = JSON.parse(localStorage.getItem("rtmToken"));
  const roomName = `room${classId}${sessionId}`;
  const roomUuid = `roomuuid${classId}${sessionId}`;
  const channel = `channel${classId}${sessionId}`;
  const userUid = userInfo?.uid;
  const roomType = 4;
  const sessionType = 3;
  const userName = profileInfo?.UserName;
  const pathName = "/private-class";

  useEffect(() => {
    localStorage.setItem("CallType", 3);
    const params = { userUid, userName, roomUuid, sessionId, roomName, channel, rtmToken, roomType, sessionType, pathName, agoraCallDuration, agoraSessionEnd };
    test(params);
  }, []);
  return;
};
export default LaunchClassRoom;
