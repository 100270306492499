import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Typography, CircularProgress } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForward from '@mui/icons-material/ArrowForward';
import Button from '@mui/material/Button';
import Header from "../header/Header";
import HeroSection from "../hero-section/HeroSection";
import FooterSection from "../footer/FooterSection";
import SubTopHead from "../sub-top-head/SubTopHead";

import { useDispatch, useSelector } from 'react-redux';
import { InlineMath, BlockMath  } from "react-katex";
import "katex/dist/katex.min.css";

import ShareWithFriend from '../common-components/ShareWithFriend';
import { openMessageModal, confirmMessageModal } from '../../actions/modalAction';


import "../../App.css";
import "../chapter/Chapter.css";
import "../subject/Subject.css";
import "../responsive/Responsive.css";

import QuickCallModal from '../common-components/QuickCallModal';
import MessageModal from '../common-components/MessageModal';
import FeedbackModal from '../common-components/FeedbackModal';
import ConfirmationModal from '../common-components/ConfirmationModal';

import useSocket from '../../custom-hooks/useSocket';
import useTranslations from '../../custom-hooks/useTranslations';
import usePracticeTest from '../../custom-hooks/usePracticeTest';
import useStudyMaterial from '../../custom-hooks/useStudyMaterial';
import { submitFeedBack } from '../../actions/submitFeedBackAction';
import { checkAnswer } from '../../actions/checkAnswerAction';
import generateAgoraToken from '../../services/AgoraTokenService';
import { encryptData } from '../../services/encryptDecrypt';
import QuestionReportModal from '../common-components/QuestionReportModal';

import { API_BASE_URL } from '../../config/apiConfig';
import { axiosPost } from '../../services/api';
// import MathJaxComponent from '../common-components/MathJax';

const PracticeTestQuestion = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { subjectId, chapterId, questionId } = useParams();
    const { socket, isConnected } = useSocket();
    const translations = useTranslations();
    const { getSubject, updateChapterQuestionCount } = useStudyMaterial()
    const subject = getSubject(atob(subjectId));
    const { getQuestion, updateAttemptedQuestion, updateQuestionStatus } = usePracticeTest(atob(subjectId), atob(chapterId))
    const question = getQuestion(atob(questionId))
    const [isCheckSolution, setIsCheckSolution] = useState(false);
    const [isCheckAnswer, setIsCheckAnswer] = useState(false);
    const [selectedOption, setSelectedOption] = useState({ ID: "", Value: "" });
    const [correctOption, setCorrectOption] = useState({ ID: "", Value: "" });
    const [correctOptionIndex, setCorrectOptionIndex] = useState("");


    const [openModal, setOpenModal] = useState(false);
    const [isFeedback, setIsFeedback] = useState(sessionStorage.getItem('StudentSessionEnd') ? true : false);
    const [feedback, setFeedback] = useState({ TutorRating: '', FeedbackMessage: [] });
    const [isReport, setIsReport] = useState(false);
    const [report, setReport] = useState({ ReportID: '', Notes: '' });
    const [isApiCalling, setIsApiCalling] = useState(false);

    const translation = {
        ...translations[21]?.CommonFrontend,
        ...translations[26]?.QuickCall,
        ...translations[36]?.PracticeTest
    }

    const studentProfile = JSON.parse(sessionStorage.getItem("ProfileInfo"));
    const isMessage = useSelector(({ modalReducer }) => modalReducer.isMessage);
    const isConfirm = useSelector(({ modalReducer }) => modalReducer.isConfirm);

    const handleBack = () => {
        // navigate(`/practice-test/${subjectId}/${chapterId}`)
        if (question.QuestionStatus === 'ATTEMPTTED') {
            dispatch(confirmMessageModal(translation?.DidYouUnderstood))
        } else {
            navigate(-1);
        }
    }

    const handleConfirmCancel = () => {
        navigate(-1);
    }

    const handleConfirmSubmit = () => {
        handleUnderstood();
        navigate(-1);
    }

    const handleOptionClick = (option) => {
        if (correctOptionIndex !== '' && question.SelectedAnswerID !== '') return;
        setIsCheckAnswer(true);
        setSelectedOption({ID: option.ID, Value: option.Value});
    }
    
    const handleAnswerCheck = () => {
        const index = question.Options.findIndex((option) => option.ID == question.AnswerID);
        setIsCheckAnswer(false);
        setCorrectOptionIndex(index);
        setCorrectOption(question.Options[index]);
        updateAttemptedQuestion(question.ID, selectedOption.ID, selectedOption.Value);
        updateChapterQuestionCount(atob(subjectId), atob(chapterId), { QuestionAttempted: 1, QuestionUnderstood: 0 })
        const obj ={
            DataList: [
                {
                    QuestionID: question.ID,
                    AnswerID: selectedOption.ID,
                    IsUnderstood: false
                }
            ],
            IsMockTest: false
        }
        dispatch(checkAnswer(obj))
    }

    const handleUnderstood = () => {
        const status = question.QuestionStatus === 'UNDERSTOOD' ? 'ATTEMPTTED' : 'UNDERSTOOD';
        const obj ={
            DataList: [
                {
                    QuestionID: question.ID,
                    AnswerID: selectedOption.ID,
                    IsUnderstood: question.QuestionStatus === 'UNDERSTOOD' ? false : true
                }
            ],
            IsMockTest: false
        }
        dispatch(checkAnswer(obj))
        updateQuestionStatus(question.ID, status)
        updateChapterQuestionCount(atob(subjectId), atob(chapterId), { 
            QuestionAttempted: 0, 
            QuestionUnderstood: question.QuestionStatus  === 'UNDERSTOOD' ? -1 : 1
        })
    }

    const handleCheckSolution = () => {
        setIsCheckSolution(!isCheckSolution);
    }

    const onChangeFeedback = (name, value) => {
        setFeedback((prev) => {
          if (name === 'FeedbackMessage') {
            const updatedFeedBack = prev?.FeedbackMessage?.includes(value)
              ? prev.FeedbackMessage.filter((item) => item !== value)
              : [...prev.FeedbackMessage, value];
      
            return {
              ...prev,
              FeedbackMessage: updatedFeedBack,
            };
          }
      
          return {
            ...prev,
            [name]: value,
          };
        });
    };

    const onSubmitFeedback = (e) => {
        setIsFeedback(false);
        const obj = {
			SessionID: sessionStorage.getItem('QuestionID'),
			TutorRating: feedback.TutorRating,
			SessionFeedbackMsg: feedback.FeedbackMessage
		}
		dispatch(submitFeedBack(obj))
        setFeedback({
            TutorRating: '',
            FeedbackMessage: []
        })
        sessionStorage.removeItem('StudentSessionEnd');
        sessionStorage.removeItem("StudentRequest");
        sessionStorage.removeItem("TutorResponse");
        sessionStorage.removeItem("QuestionID");
	}

    const onChangeReport = (name, value) => {
        setReport((prev) => ({
            ...prev,
            [name]: value
        }))
	}

    const onSubmitReport = async () => {
        setIsReport(false);
        const obj = {
            "TestID": atob(chapterId),
            "QuestionID": atob(questionId),
            "ReportID": report?.ReportID,
            "Notes": report?.Notes,
            "IsMockTest": false
		}
        await axiosPost(`${API_BASE_URL}/SubmitReportQuestion`, obj)
	}

    const handleAskTutor = () => {
        setOpenModal(true);
        setIsApiCalling(true);
		const payload = {
			QuestionID: 0,
			SubjectID: atob(subjectId),
			SubTopicID: atob(chapterId),
			SubjectName: subject?.SubjectName,
			Notes: question?.Question,
			ImageURL: '',
			StudentID: studentProfile?.ID,
			StudentName: studentProfile?.UserName,
			StudentImage: studentProfile?.ProfilePictureUrl,
			StudentClass: studentProfile?.Grade,
			StudentClassID: studentProfile?.StudentGradeID.toString(),
			TutorID: "",
			TutorName: "",
			TutorImage: "",
			TutorSubject: "",
			EnglishMessage: "",
			ArabicMessage: "",
			requestType: "1",
			ReasonID: "",
			IsAccept: false,
			IsDecline: false
		}

		const stringifiedObj = JSON.stringify(payload);
		socket.emit("QCQuestions", stringifiedObj, 'QC');
	};

    const cancelRequest = () => {
        setIsApiCalling(false);
        const studentRequest = JSON.parse(sessionStorage.getItem('StudentRequest'));
		if (!socket || !socket.connected) {
			// If socket is not initialized or not connected, do not emit the event
			console.error('Socket is not connected. Unable to cancel request.');
			return;
		}
		studentRequest.IsDecline = true;
		studentRequest.requestType = "1";
		socket.emit("QCQuestions", JSON.stringify(studentRequest), 'QC');
        socket.off('GetOnlineTutorResponseEvent');
        sessionStorage.removeItem("TutorResponse")
        sessionStorage.removeItem("StudentRequest")
	};

    const closeModal = (event, reason) => {
		if (reason && reason === "backdropClick" && "escapeKeyDown") {
			return;
		}
		setOpenModal(false);
		cancelRequest();
	};

    useEffect(() => {
        if (Object.keys(question).length > 0) {
            if (question?.SelectedAnswerID && !selectedOption.ID && (question.QuestionStatus === 'UNDERSTOOD' || question.QuestionStatus === 'ATTEMPTTED' )) {
                setSelectedOption({
                    ID: question?.SelectedAnswerID, 
                    Value: question?.SelectedAnswer
                })
            }
            if (question?.SelectedAnswerID && !correctOption.ID && (question.QuestionStatus === 'UNDERSTOOD' || question.QuestionStatus === 'ATTEMPTTED' )) {
                const index = question?.Options.findIndex((option) => option.ID == question.AnswerID)
                setIsCheckSolution(true);
                setCorrectOptionIndex(index);
                setCorrectOption({
                    ID: question.AnswerID, 
                    Value: question.Answer
                })
            }
        }

    }, [question])

    useEffect(() => {
		if (isConnected) {
			socket.on("QCQuestions", (data) => {
				const response = JSON.parse(data);

				if (response?.StatusCode === 200) {
					const QuestionQCData = response?.Data
					if (QuestionQCData?.requestType == "1" && QuestionQCData?.IsDecline == false) {
						// setOpenModal(true);
                        setIsApiCalling(false);
                        sessionStorage.setItem('StudentRequest', JSON.stringify(response?.Data));

                        const payload = {}
                        payload["langid"] = localStorage.getItem("langId");
                        payload['DeviceID'] = sessionStorage.getItem('deviceId');
                        payload['authorization'] = JSON.parse(sessionStorage.getItem('loginToken'))?.idToken;
                        payload["StudentID"] = response?.Data?.StudentID;
                        payload["SubjectID"] = response?.Data?.SubjectID;
                        payload["SessionID"] = response?.Data?.QuestionID?.toString();

                        socket?.emit('GetOnlineTutor', JSON.stringify(payload));
                        
					} else if (QuestionQCData.requestType == "2" && QuestionQCData.IsAccept == true) {
                        const studentRequest = JSON.parse(sessionStorage.getItem('StudentRequest'));
						if (parseInt(studentRequest?.QuestionID) === parseInt(QuestionQCData?.QuestionID)) {
                            sessionStorage.setItem('pathName', window.location.pathname);
							sessionStorage.setItem('QuestionID', studentRequest?.QuestionID);
							sessionStorage.setItem('TutorResponse', JSON.stringify(QuestionQCData))
							const userUid = studentProfile?.UID;
							const channelName = `channel${studentRequest?.QuestionID}`;
							generateAgoraToken(userUid, 2, channelName).then((res) => {
								localStorage.setItem("rtmToken", JSON.stringify(res.RtmToken));
								localStorage.setItem("rtcToken", JSON.stringify(res.RtcToken));
								navigate(`/quick-calls-room/${encryptData(studentRequest?.QuestionID)}`);
							});
						}
					}
				} else if (response.StatusCode === 400) {
                    setOpenModal(false);
                    dispatch(openMessageModal(translation?.ExceededFreeCallLimit))
				} else if (response.StatusCode === 402) {
                    setOpenModal(false);
					dispatch(openMessageModal(translation?.LowAmountMessage))
				} else {
                    setOpenModal(false);
                }
			})
		}

	}, [isConnected])

    useEffect(() => {
        window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub]);
    }, []);

  return (
    <div>
    <Header/>
     <HeroSection/>
        <Box className='OuterContainer' sx={{background:"#fff"}}>
            <Box className='studyContainer'>
                {/* <SubTopHead subject={subject} /> */}
                <Box className='subHeading'>
                    <Box className="cardMain lightGray">
                        <Box className="BackBtn">
                            <Button className='backBtn' variant="text" sx={{mb:2}} startIcon={localStorage.getItem("I18N_LANGUAGE") === "en" ? <ArrowBackIcon /> : <ArrowForward />} onClick={handleBack}> {translation?.BackBtn} </Button>
                        </Box>
                        
                        <Box className="cardTop" sx={{ display: {xs: 'flex', alignItems: 'center', justifyContent: 'space-between'} }}>
                            <Box className="cardRight" sx={{ display: {xs: 'flex', alignItems: 'center'} }}>
                                <Box className="cardIcon">
                                    <img src={subject?.SubjectImageURL} alt=""></img>
                                </Box>
                                <Box className="cardContent">
                                    <Typography variant='h4'>{subject?.SubjectName}</Typography>
                                    <Box className="cardList">
                                        <ul>
                                            <li>{subject?.NumberOfChapter} {translation?.PracticeChapter}</li>
                                            <li>{subject?.NumberOfQuestion} {translation?.PracticeQuestion}</li>
                                        </ul>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>

                <Box className="practiceBox">
                    <Box className='practiceBoxIn'>
                        <ShareWithFriend />

                        <Box className="mainHeading">
                            <Typography variant='h3' sx={{mb:1}}>
                            {translation?.PracticeQuestion}
                            </Typography>
                        </Box>

                        <Box className="Classification">
                            <Box className="cardMain" sx={{ mb:1}}>
                                <Box className="cardTop">
                                    <Box className="cardRight">
                                        <Box sx={{ mb: 1, display:{xs: 'flex', alignItems: 'center', justifyContent: 'space-between'}}}>
                                            <Typography variant='div' sx={{color: '#005db8', fontWeight: '600', fontSize: '18px'}}>
                                                {question?.QuestionIndex}
                                            </Typography>
                                            <Box onClick={() => setIsReport(true)}>
                                                <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8 12.923C8.17467 12.923 8.321 12.864 8.439 12.746C8.55633 12.628 8.615 12.482 8.615 12.308C8.615 12.134 8.556 11.9877 8.438 11.869C8.32 11.7503 8.174 11.6913 8 11.692C7.826 11.6927 7.68 11.7517 7.562 11.869C7.444 11.9863 7.385 12.1327 7.385 12.308C7.385 12.4833 7.444 12.6293 7.562 12.746C7.68 12.8627 7.826 12.9217 8 12.923ZM7.5 9.962H8.5V3.884H7.5V9.962ZM4.673 16.5L0 11.836V5.173L4.664 0.5H11.327L16 5.164V11.827L11.336 16.5H4.673ZM5.1 15.5H10.9L15 11.4V5.6L10.9 1.5H5.1L1 5.6V11.4L5.1 15.5Z" fill="#555555"/>
                                                </svg>
                                            </Box>
                                        </Box>
                                        <Box className="cardContent">
                                            {/* <Typography variant='h4' sx={{ mb:1}}><InlineMath math={question?.Question} /></Typography> */}
                                            <Typography variant='h4' sx={{ mb:1}} dangerouslySetInnerHTML={{__html: question?.Question}}></Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box className="selectOption">
                                    <Typography sx={{mb:2}}>
                                        {translation?.SelectOneOption}
                                    </Typography>
                                    <Box className="questioList">
                                    { question?.Options?.map((option, index) => {
                                        const isSelected = selectedOption.ID === option.ID && correctOptionIndex === '';
                                        const isCorrect = correctOption.ID === option.ID && correctOption.ID === selectedOption.ID;
                                        const isCorrectAnswer = correctOption.ID === option.ID;
                                        const isIncorrect = correctOptionIndex !== '' && selectedOption.ID === option.ID && selectedOption.ID !== correctOption.ID;

                                        const className = isSelected ? 'blue' : isIncorrect ? 'pink' : isCorrect ? 'green' : isCorrectAnswer ? 'blue' : '';


                                        return (
                                            <Box
                                                className={`questionListIn ${className}`}
                                                sx={{ mb: 1, display: 'flex', alignItems: 'center' }}
                                                key={option?.ID}
                                                onClick={() => handleOptionClick(option)}
                                            >
                                                <Box className="cardIcon">{String.fromCharCode(65 + index)}</Box>
                                                <Box>
                                                    <Typography dangerouslySetInnerHTML={{__html: option?.Value}}></Typography>
                                                    {isIncorrect && (
                                                        <Typography variant="div">
                                                            {translation?.WrongAnswer}
                                                        </Typography>
                                                    )} 
                                                    {(isCorrect || isCorrectAnswer) && selectedOption !== '' && (
                                                        <Typography variant="div">
                                                            {translation?.CorrectAnswer}
                                                        </Typography>
                                                    )} 
                                                </Box>
                                                {/* <Typography>
                                                    {option.Value}
                                                    {isIncorrect && (
                                                        <Typography variant="div">
                                                            {translation?.WrongAnswer}
                                                        </Typography>
                                                    )}
                                                    {(isCorrect || isCorrectAnswer) && selectedOption !== '' && (
                                                        <Typography variant="div">
                                                            {translation?.CorrectAnswer}
                                                        </Typography>
                                                    )}
                                                </Typography> */}
                                            </Box>
                                        );
                                    })}
                                    </Box>

                                    {/* {  correctOptionIndex !== ""  &&
                                        <Box className="understoodBox">
                                            <Typography sx={{mt:1, mb:2, display:{xs: 'flex', alignItems: 'center'}}}>
                                                <Typography variant='div' sx={{mr:1, cursor: 'pointer'}} onClick={handleUnderstood}>
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="12" cy="12" r="11" fill="#005DB8"/>
                                                    <circle cx="12" cy="12" r="11" fill={question.QuestionStatus === "UNDERSTOOD" ? '#005DB8' : '#FFB800'} />
                                                    <path d="M10.243 16.3151L6 12.0711L7.414 10.6571L10.243 13.4851L15.899 7.82812L17.314 9.24312L10.243 16.3151Z" fill="white"/>
                                                    </svg>
                                                </Typography>
                                            {translation?.PracticeUnderstood}
                                            </Typography>
                                        </Box>
                                    } */}

                                    { correctOptionIndex !== ""  && !isCheckSolution && 
                                        <Box className="QuestionSolution" sx={{mt:3}}>
                                            <Box className="cardMain orgbdr lightYellow">
                                                <Box className="cardTop" sx={{ display: {xs: 'flex', alignItems: 'center', justifyContent: 'space-between'} }}>
                                                    <Box className="cardRight" sx={{ display: {xs: 'flex', alignItems: 'center'} }}>
                                                        <Box className="cardIcon YellowCircle">
                                                        <Typography variant='div'>
                                                        <svg width="34" height="34" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/  svg">
                                                        <path d="M8.51625 3.00781L6.75625 4.76531L9.375 7.38281L11.1325 5.62531L8.51625 3.00781ZM31.485 3.00781L28.8663 5.62531L30.625 7.38281L33.2425 4.76656L31.485 3.00781ZM20 3.78781C19.5875 3.79281 19.17 3.81656 18.75 3.86781C18.7375 3.86781 18.725 3.86531 18.7125 3.86781C13.6425 4.44906 9.6075 8.54531 8.90625 13.5928C8.34625 17.6553 10.0212 21.3391 12.8125 23.7116C13.9543 24.686 14.7265 26.0231 15 27.4991V34.9991H17.85C18.285 35.7453 19.0788 36.2491 20 36.2491C20.9212 36.2491 21.715 35.7453 22.15 34.9991H25V29.9991H25.1175V28.5141C25.1175 26.6816 26.07 24.8341 27.6175 23.3978C29.6875 21.3253 31.25 18.3816 31.25 15.0003C31.25 8.82531 26.1675 3.73031 20 3.78781ZM20 6.28781C24.8312 6.22031 28.75 10.1753 28.75 15.0003C28.75 17.6178 27.5375 19.9228 25.8587 21.6003L25.8988 21.6403C24.2305 23.1787 23.1517 25.2513 22.8487 27.5003H17.3812C17.1062 25.3578 16.1875 23.2841 14.4513 21.7966C12.2425 19.9216 10.9263 17.0966 11.3663 13.9066C11.9125 9.96906 15.1075 6.80281 19.0225 6.36906C19.3457 6.324 19.6712 6.29729 19.9975 6.28906L20 6.28781ZM2.5 15.0003V17.5003H6.25V15.0003H2.5ZM33.75 15.0003V17.5003H37.5V15.0003H33.75ZM9.375 25.1178L6.7575 27.7341L8.51625 29.4928L11.1312 26.8753L9.375 25.1178ZM30.625 25.1178L28.8675 26.8753L31.4837 29.4928L33.2425 27.7341L30.625 25.1178ZM17.5 30.0003H22.5V32.5003H17.5V30.0003Z" fill="black"/>
                                                        </svg>
                                                        </Typography>
                                                        </Box>
                                                        <Box className="cardContent">
                                                            <Typography variant='h4'>{translation?.PracticeSolutions}</Typography>
                                                        </Box>
                                                        
                                                    </Box>
                                                    <Box className="cardLeft">
                                                        <Button variant="contained" className='darkYellow' onClick={handleCheckSolution}>{translation?.CheckSolution}</Button>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box> 
                                    }

                                    { isCheckSolution && 
                                        <Box className="rightSolution orgbdr radius8">
                                            <Box className="bdrDash solutionSmall" sx={{display:{xs: 'flex', alignItems: 'center', justifyContent: 'space-between'}}}>
                                                <Box sx={{display:{xs: 'flex', alignItems: 'center'}}}>
                                                    <Typography variant='div' className='YellowCircle' sx={{mr:1}}>
                                                    <svg width="28" height="28" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/  svg">
                                                        <path d="M8.51625 3.00781L6.75625 4.76531L9.375 7.38281L11.1325 5.62531L8.51625 3.00781ZM31.485 3.00781L28.8663 5.62531L30.625 7.38281L33.2425 4.76656L31.485 3.00781ZM20 3.78781C19.5875 3.79281 19.17 3.81656 18.75 3.86781C18.7375 3.86781 18.725 3.86531 18.7125 3.86781C13.6425 4.44906 9.6075 8.54531 8.90625 13.5928C8.34625 17.6553 10.0212 21.3391 12.8125 23.7116C13.9543 24.686 14.7265 26.0231 15 27.4991V34.9991H17.85C18.285 35.7453 19.0788 36.2491 20 36.2491C20.9212 36.2491 21.715 35.7453 22.15 34.9991H25V29.9991H25.1175V28.5141C25.1175 26.6816 26.07 24.8341 27.6175 23.3978C29.6875 21.3253 31.25 18.3816 31.25 15.0003C31.25 8.82531 26.1675 3.73031 20 3.78781ZM20 6.28781C24.8312 6.22031 28.75 10.1753 28.75 15.0003C28.75 17.6178 27.5375 19.9228 25.8587 21.6003L25.8988 21.6403C24.2305 23.1787 23.1517 25.2513 22.8487 27.5003H17.3812C17.1062 25.3578 16.1875 23.2841 14.4513 21.7966C12.2425 19.9216 10.9263 17.0966 11.3663 13.9066C11.9125 9.96906 15.1075 6.80281 19.0225 6.36906C19.3457 6.324 19.6712 6.29729 19.9975 6.28906L20 6.28781ZM2.5 15.0003V17.5003H6.25V15.0003H2.5ZM33.75 15.0003V17.5003H37.5V15.0003H33.75ZM9.375 25.1178L6.7575 27.7341L8.51625 29.4928L11.1312 26.8753L9.375 25.1178ZM30.625 25.1178L28.8675 26.8753L31.4837 29.4928L33.2425 27.7341L30.625 25.1178ZM17.5 30.0003H22.5V32.5003H17.5V30.0003Z" fill="black"/>
                                                        </svg>
                                                    </Typography>
                                                    <Typography  sx={{margin:0}}>
                                                        {translation?.PracticeSolutions}
                                                    </Typography>
                                                </Box>


                                            <Box className="understoodBox" sx={{ ml: '20px' }}>
                                                    <Typography sx={{mt:1, mb:2, display:{xs: 'flex', alignItems: 'center'}}}>
                                                        <Typography variant='div' sx={{mr:1, cursor: 'pointer'}} onClick={handleUnderstood}>
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <circle cx="12" cy="12" r="11" fill="#005DB8"/>
                                                            <circle cx="12" cy="12" r="11" fill={question.QuestionStatus === "UNDERSTOOD" ? '#005DB8' : '#fff'} stroke={question.QuestionStatus === "UNDERSTOOD" ? 'transparent' : '#005DB8'} />
                                                            <path d="M10.243 16.3151L6 12.0711L7.414 10.6571L10.243 13.4851L15.899 7.82812L17.314 9.24312L10.243 16.3151Z" fill={question.QuestionStatus === "UNDERSTOOD" ? '#fff' : '#005DB8'}/>
                                                            </svg>
                                                        </Typography>
                                                    {translation?.PracticeUnderstood}
                                                    </Typography>
                                                </Box>
                                            </Box>

                                            <Box className="SolutionSummary">
                                                <Typography variant='h3' sx={{mb:1}}>
                                                    {translation?.CorrectOption} {String.fromCharCode(65 + correctOptionIndex)}
                                                </Typography>
                                                <Typography dangerouslySetInnerHTML={{__html: question?.Solution}}>
                                                    {/* <InlineMath math={question?.Solution} /> */}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    }

                                    { correctOptionIndex !== "" && question.QuestionStatus !== 'UNDERSTOOD' &&
                                        <Box className="tutorBtnBox" sx={{mt:2}}>
                                            <button className='tuterBtn' style={{display:{xs: 'flex', alignItems: 'center', justifyContent:'center'}}} disabled={isApiCalling} onClick={handleAskTutor}>
                                                <Typography variant='div' sx={{display:{xs: 'flex', alignItems: 'center', justifyContent:'center'}}}>
                                                    <svg width="20" height="20" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <rect width="30" height="30" rx="15" transform="matrix(-1 0 0 1 30 0)" fill="white"/>
                                                        <path d="M9.17493 21.4788L10.4634 18.782C10.7407 18.2058 11.0992 17.7864 11.718 18.0421L12.9388 18.499C13.9162 18.9558 14.4031 18.499 14.8935 17.8103L17.0916 12.7677C17.3689 12.1915 17.2235 11.6494 16.6047 11.3937L14.8969 10.705C14.278 10.4458 14.3761 9.90714 14.6534 9.33095L16.1177 6.58292C16.395 6.00673 16.9631 5.86694 17.582 6.12265C18.8197 6.63748 19.8444 7.44552 20.5106 8.64224C21.3222 10.1049 20.9164 12.1403 20.7541 12.9961C20.5917 13.8519 20.0236 15.352 19.2898 16.8897C18.5559 18.4308 17.9134 19.6411 17.3351 20.3264C16.7602 21.0117 15.3805 22.8869 13.6727 23.3063C12.2726 23.6473 10.761 23.3609 9.52326 22.846C8.90101 22.6005 8.90101 22.0584 9.17493 21.4788Z" fill="#005DB8"/>
                                                    </svg>
                                                    {translation?.PractionAskTutor}
                                                </Typography>
                                            </button>
                                        </Box>
                                    }
                                </Box>
                            </Box>
                            
                           
                        </Box>
                        { isCheckAnswer &&
                            <Box className="checkAnswer" sx={{mt:3, display:{xs: 'flex', alignItems: 'center', justifyContent:'center'}}}>
                                <button variant='div' className='checkAnswerBtn' onClick={handleAnswerCheck}>{translation?.CheckAnswer}</button>
                            </Box>
                        }
                    </Box>
                </Box>
            </Box>
        </Box>
   
        <MessageModal isOpen={isMessage} btnTxt={translation?.OkButton} />
        <QuickCallModal isOpen={openModal} isApiCalling={isApiCalling} translation={translation} handleClose={closeModal} />
        <ConfirmationModal isOpen={isConfirm} doneBtnText={translation?.YesLabel} cancelBtnText={translation?.NoLabel} handleClose={handleConfirmCancel} handleSubmit={handleConfirmSubmit} />
        <FeedbackModal isOpen={isFeedback} feedback={feedback} translation={translation} onChangeFeedback={onChangeFeedback} onSubmitFeedback={onSubmitFeedback} />
        <QuestionReportModal isOpen={isReport} report={report} translation={translation} setIsReport={setIsReport} onChangeReport={onChangeReport} onSubmitReport={onSubmitReport} />
        <FooterSection/>
    </div>
  )
}

export default PracticeTestQuestion